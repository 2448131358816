import React from 'react'
import Helmet from 'react-helmet'

import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import Heading from 'components/Heading'
import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'

const About = () => (
  <>
    <Helmet title="About | François Deguire" />
    {/* Intro */}
    <Section>
      <Wrapper>
        <GridRow>
          <GridItem xs="1-1" md="3-4" lg="2-3">
            <Heading>
              I'm a multidisciplinary designer based in Montréal.
            </Heading>
            <Heading>
              Recently majored in industrial design at Université de Montréal.
            </Heading>
            <Heading>
              I am passionate about technology and user experiences.
            </Heading>
          </GridItem>
        </GridRow>
      </Wrapper>
    </Section>

    {/* Work */}
    <Section flushTop>
      <Wrapper>
        <GridRow>
          <GridItem xs="1-1" md="3-4" lg="2-3">
            <Heading>Work</Heading>
            <p>
              Industrial Design Intern
              <br />
              ALTO Design
              <br />
              June 2019 - Sept 2019
            </p>
            <p>
              Teaching Assistant, Advanced 3D Modeling
              <br />
              Université De Montréal
              <br />
              Sept 2018 - Dec 2019
            </p>
            <p>
              Media Specialist, Copywriter and Translator
              <br />
              BAM Strategy
              <br />
              June 2016 - Nov 2017
            </p>
            <p>
              Computer Technician
              <br />
              Staples
              <br />
              June 2017 - Nov 2018
            </p>
            <p>
              Photographer
              <br />
              REDLight Photo Collective
              <br />
              Sept 2017 - Apr 2020
            </p>
          </GridItem>
        </GridRow>
      </Wrapper>
    </Section>

    {/* Education */}
    <Section flushTop>
      <Wrapper>
        <GridRow>
          <GridItem xs="1-1" md="3-4" lg="2-3">
            <Heading>Education</Heading>
            <p>
              Interaction Design Specialization
              <br />
              UC San Diego (remote)
              <br />
              Sept 2020 - Present
            </p>
            <p>
              BA Industrial Design
              <br />
              Université de Montréal
              <br />
              Sept 2016 - May 2020
            </p>
            <p>
              Certificate in Communications
              <br />
              Université de Montréal
              <br />
              Sept 2015 - May 2016
            </p>
            <p>
              College Diploma in Arts & Sciences
              <br />
              Collège Bois-De-Boulogne
              <br />
              Oct 2012 - June 2015 
            </p>
          </GridItem>
        </GridRow>
      </Wrapper>
    </Section>
  </>
)

export default About
